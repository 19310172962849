module.exports = [{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/yazeed/Desktop/yazeedb-gatsby-2019/src/cms/index.js"},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-136388820-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yazeed/Desktop/yazeedb-gatsby-2019/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
